<template>
  <v-container>
    <v-toolbar rounded>
      <v-toolbar-title>Productos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="newForm">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="code"
      sort-by="category,product_name"
      mobile-breakpoint="10"
      class="table-cursor mt-3"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <!-- <template v-slot:top>
        <v-text-field v-model="search" label="Buscar" class="mx-4" />
      </template> -->
      <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !==''">
        <v-list-item-avatar>
          <v-img :src="getImagePhoto(item.account, item.picture)"></v-img>
        </v-list-item-avatar>
      </template>
      <template v-slot:[`item.product_name`]="{ item }">
        {{ item.product_name + " x " + item.size + " " + item.uom }} <br />
        <small>
          {{ item.category + " " + item.brand }}
        </small>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
      </template>
    </v-data-table>

    <ProductForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      :store="store"
      @close="dialog = false"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="suppliers"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import ProductForm from "../components/ProductForm.vue";

export default {
  components: { ProductForm },
  data() {
    return {
      table: "catalogue",
      search: "",
      errors: [],
      store: "000",
      storesLst: [],
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      byCategories: [],
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      headers: [
        {
          text: "",
          align: "center",
          value: "picture",
          width: 50,
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      items: [],
      item: createItem(),
      test: [
        {
          action: "mdi-ticket",
          items: [{ title: "List Item" }],
          title: "Attractions",
        },
        {
          action: "mdi-silverware-fork-knife",
          active: true,
          items: [
            { title: "Breakfast & brunch" },
            { title: "New American" },
            { title: "Sushi" },
          ],
          title: "Dining",
        },
        {
          action: "mdi-school",
          items: [{ title: "List Item" }],
          title: "Education",
        },
        {
          action: "mdi-run",
          items: [{ title: "List Item" }],
          title: "Family",
        },
        {
          action: "mdi-bottle-tonic-plus",
          items: [{ title: "List Item" }],
          title: "Health",
        },
        {
          action: "mdi-content-cut",
          items: [{ title: "List Item" }],
          title: "Office",
        },
        {
          action: "mdi-tag",
          items: [{ title: "List Item" }],
          title: "Promotions",
        },
      ],
    };
  },
  mounted() {
    this.storesLst = window.stores;
    this.get_items();
  },
  methods: {
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    get_items() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      webserver("get_table", qry, (data) => {
        data.forEach((rg) => {
          if (rg.brand) rg.brand = rg.brand.toUpperCase();
          if (rg.supplier) rg.supplier = rg.supplier.toUpperCase();
          if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
        });

        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));
        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.byCategories = ctgry;
        this.categories = Object.keys(ctgry).sort();

        var sbctgry = data.reduce(function (r, a) {
          r[a.subcategory] = r[a.subcategory] || [];
          r[a.subcategory].push(a);
          return r;
        }, Object.create(null));
        this.subcategories = Object.keys(sbctgry).sort();

        var brnd = data.reduce(function (r, a) {
          r[a.brand] = r[a.brand] || [];
          r[a.brand].push(a);
          return r;
        }, Object.create(null));
        this.brands = Object.keys(brnd).sort();

        var spplr = data.reduce(function (r, a) {
          r[a.supplier] = r[a.supplier] || [];
          r[a.supplier].push(a);
          return r;
        }, Object.create(null));
        this.suppliers = Object.keys(spplr).sort();

        this.loading_status = false;
        this.items = data;
        if (data.length === 0) this.newForm();
      });
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url = "https://smartchef.pro/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}
.centered-input input {
  text-align: center;
}
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
