<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px" :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar class="mb-6">
              <v-tabs show-arrows v-model="tab">
                <v-tab key="product">
                  <v-icon left> mdi-food-apple-outline </v-icon>
                </v-tab>
                <v-tab key="photo">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab key="note">
                  <v-icon> mdi-note-edit-outline </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn x-small color="info" fab @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab" class="pa-2">
              <v-tab-item key="producto">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="item.product_name" id="product_name" hide-details="auto" label="Nombre"
                      outlined required dense />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox v-model="item.category" id="category" hide-details="auto" label="Categoría"
                      :items="categories" :rules="f_required" outlined required dense />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox v-model="item.subcategory" id="subcategory" hide-details="auto" label="Sub-categoria"
                      :items="subcategories" :rules="f_required" outlined required dense />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox v-model="item.brand" id="brand" hide-details="auto" label="Marca" :items="brands"
                      :rules="f_required" outlined required dense @keyup="returnCap" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="item.reference" id="reference" hide-details="auto" label="Referencia"
                      :rules="f_required" outlined required dense @keyup="returnCap" />
                  </v-col>
                  <v-col cols="6" md="8">
                    <v-text-field class="centered-input" hide-details="auto" :rules="f_required" v-model="item.size"
                      @keypress="onlyNumber" label="Tamaño" outlined required dense />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-select v-model="item.uom" hide-details="auto" :rules="f_required" item-text="text"
                      item-value="value" :items="uom_lst" label="UOM" outlined dense />
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field v-model="item.price" label="Precio" class="centered-input" hide-details="auto"
                      @keypress="onlyNumber" prepend-inner-icon="mdi-currency-usd" outlined required dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="item.sku" hide-details="auto" label="SKU/EAN/CODIGO"
                      append-icon="mdi-barcode-scan" outlined required dense />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-img contain aspect-ratio="1" class="grey lighten-5" :src="productPhoto" max-height="250">
                  <v-fab-transition>
                    <v-btn class="mx-2 btn-upload" fab light small color="secondary" @click="chooseImage">
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input class="file-input" ref="fileInput" type="file" @input="onSelectFile" />
              </v-tab-item>
              <v-tab-item key="note">
                <v-row>
                  <v-col cols="12" md="12" class="vueEditorCSS">
                    <vue-editor v-model="item.description" :editorToolbar="customToolbar"></vue-editor>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-bottom-navigation grow>
                <v-btn @click="delete_itm(item)" :disabled="newItem">
                  <span>Borrar</span>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
                <v-btn @click="edit = !edit">
                  <span>Editar</span>
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn :disabled="!valid" type="submit">
                  <span>Guardar</span>
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-bottom-navigation>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-dialog> -->
    </v-dialog>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn large text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Formato de archivo o tamaño no valido. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
//import createItem from "../utils/createItem.js";
import { VueEditor } from "vue2-editor";
export default {
  name: "ProductForm",
  components: { VueEditor },
  data() {
    return {
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        //[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      default: "",
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
    subcategories: {
      type: Array,
      required: false,
    },
    brands: {
      type: Array,
      required: false,
    },
    suppliers: {
      type: String,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() { },
  methods: {
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");

            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            this.loading_status = false;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        if (files[0].size <= 10000000) {
          this.loading_status = true;
          reader.readAsDataURL(files[0]);
          this.item.picture = files[0].name.toLowerCase();
        } else {
          this.alerts = true;
          this.loading_status = false;
        }
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      //console.log(e);
      e.preventDefault();

      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var normalizer = 1;
        switch (this.item.uom) {
          case "LB":
            normalizer = 453;
            this.item.normalized_uom = "GR";
            break;
          case "LT":
            normalizer = 1000;
            this.item.normalized_uom = "ML";
            break;
          case "KG":
            normalizer = 1000;
            this.item.normalized_uom = "GR";
            break;
          case "OZ":
            normalizer = 28.34;
            this.item.normalized_uom = "GR";
            break;
          case "GL":
            normalizer = 3785.4;
            this.item.normalized_uom = "ML";
            break;
          case "ML":
            normalizer = 1;
            this.item.normalized_uom = "ML";
            break;
          case "UN":
            normalizer = 1;
            this.item.normalized_uom = "UN";
            break;
          case "GR":
            normalizer = 1;
            this.item.normalized_uom = "GR";
            break;
          case "Mes":
            normalizer = 1;
            this.item.normalized_uom = "Mes";
            break;
          case "Año":
            normalizer = 1;
            this.item.normalized_uom = "Año";
            break;
          case "Día":
            normalizer = 1;
            this.item.normalized_uom = "Día";
            break;
          default:
            normalizer = 1;
            this.item.normalized_uom = "UN";
        }
        this.item.normalized_size = parseFloat(this.item.size) * normalizer;
        this.item.cost_uom =
          parseFloat(this.item.price) / parseFloat(this.item.normalized_size);
        this.item.store = this.store;
        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }

        webserver("put_table", qry, () => {
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    dialog: function () {
      // if (!e.picture) {
      //   this.compressed_image = null;
      // }
      ////console.log(e);
     
    },
    item: function (e) {
      console.log(e);
       if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, "000000", e.picture);
      } else {
        this.productPhoto = null;
      }
      console.log(this.productPhoto);
      // this.item.supplier = this.suppliers.find(
      //   (prv) => prv.code.toUpperCase() === this.item.supplier.toUpperCase()
      // );

      //this.productPhoto = this.getImagePhoto(e.account, e.store, e.picture);
      //console.log(this.item);
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}

.bar-top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>